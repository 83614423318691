<template>
  <div>Coming Soon!!</div>
</template>

<script>
export default {
  name: "Invest",
  metaInfo: {
    title: 'Invest Tools',
  },
}
</script>

<style scoped>

</style>
